"use client";

import React, { useEffect, useRef, useState } from "react";
import Box from "./Box";
import { Player } from "./Player";
import { useEnteredViewport } from "../core/hooks";

function LottieOnEntered({ src, threshold, delay, ...props }) {
  const playerRef = useRef();
  const { ref: containerRef, entered } = useEnteredViewport(threshold);
  const [canPlay, setCanPlay] = useState(false);

  useEffect(() => {
    if (entered) {
      if (delay) {
        setTimeout(() => {
          setCanPlay(true);
        }, delay);
      } else {
        setCanPlay(true);
      }
    }
  }, [entered]);

  return (
    <Box ref={containerRef} {...props}>
      {entered && canPlay && (
        <Player
          ref={playerRef}
          src={src}
          loop={false}
          autoplay={true}
          rendererSettings={{
            preserveAspectRatio: "xMidYMid meet",
          }}
          keepLastFrame={true}
        />
      )}
    </Box>
  );
}

export default LottieOnEntered;
