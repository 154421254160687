import { DARK, LIGHT } from "../core/constants";

import Box from "./Box";
import Cart from "./Cart";
import Footer from "./Footer";
import MainMenu from "./MainMenu";
import Nav from "./Nav";
import PropTypes from "prop-types";
import React from "react";
import Text from "./Text";
import Ticker from "./Ticker";

function Layout({ navItems, globalData, brand, pageTheme, children }) {
  const navTheme = !!brand ? DARK : LIGHT;

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <Cart />
      {globalData.tickerItems.length > 0 && (
        <Ticker
          height={30}
          bg="yellow"
          tickerItem={
            <div className="main-ticker-item">
              {globalData.tickerItems.map((item, index) => {
                return (
                  <span
                    key={index}
                    className="main-ticker-html"
                    dangerouslySetInnerHTML={{ __html: item.value }}
                  />
                );
              })}
            </div>
          }
        />
      )}

      <Nav theme={navTheme} navItems={navItems} pageTheme={pageTheme} />
      <MainMenu navItems={navItems} />

      {children}

      <Footer data={globalData} />
    </Box>
  );
}

export default Layout;

Layout.propTypes = {
  navItems: PropTypes.array.isRequired,
  pageTheme: PropTypes.string,
  brand: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  children: PropTypes.node.isRequired,
};
